import React from "react"
import { handleAuthentication } from "../utils/auth"
import Loading from "../components/Loading"

const Callback = () => {
  handleAuthentication()

  return <Loading active={true} />
}

export default Callback